import { useState } from 'react'
import * as Yup from 'yup'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Stack, Alert, IconButton, InputAdornment } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// auth
import { useAuthContext } from 'src/auth/useAuthContext'
// components
import Iconify from 'src/components/iconify'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { useLocales } from 'src/locales'
import LanguageSelect from './LanguageSelect'

// ----------------------------------------------------------------------

type FormValuesProps = {
	email: string
	password: string
	afterSubmit?: string
}

export default function AuthLoginForm() {
	const { login } = useAuthContext()
	const { translate } = useLocales()

	const [showPassword, setShowPassword] = useState(false)

	const LoginSchema = Yup.object().shape({
		email: Yup.string().required('Email is required'),
		password: Yup.string().required('Password is required'),
	})

	const defaultValues = {
		email: '',
		password: '',
	}

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	})

	const {
		reset,
		setError,
		handleSubmit,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = methods

	const onSubmit = async (data: FormValuesProps) => {
		try {
			await login(data.email, data.password)
		} catch (error) {
			console.error(error)
			reset()

			setError('afterSubmit', {
				...error,
				message: error.message,
			})
		}
	}

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				{!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit.message}</Alert>}

				<RHFTextField name='email' label={`${translate('loginOrEmail')}`} />

				<RHFTextField
					name='password'
					label={`${translate('password')}`}
					type={showPassword ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
									<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				<LoadingButton
					fullWidth
					color='inherit'
					size='large'
					type='submit'
					variant='contained'
					loading={isSubmitSuccessful || isSubmitting}
					sx={{
						bgcolor: 'text.primary',
						color: theme => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
						'&:hover': {
							bgcolor: 'text.primary',
							color: theme => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
						},
					}}
				>
					{`${translate('login')}`}
				</LoadingButton>
				<LanguageSelect />
			</Stack>
		</FormProvider>
	)
}

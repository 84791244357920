import { createSlice } from '@reduxjs/toolkit'
import { IScript, IScriptState } from 'src/@types/script'
import axios from 'src/utils/axios'

const initialState: IScriptState = {
	isLoading: false,
	error: null,
	scripts: [],
    currentID: null,
}

const slice = createSlice({
	name: 'script',
	initialState,
	reducers: {
		startLoading(state) {
			state.isLoading = true
		},

		stopLoading(state) {
			state.isLoading = false
		},

		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getScriptSuccess(state, action) {
			state.isLoading = false
			state.scripts = action.payload
		},

		saveScriptSuccess(state, action) {
			state.isLoading = false
			state.scripts = state.scripts.map((e: IScript) => (e.id === action.payload.id) ? action.payload : e)
		},

		deleteScriptSuccess(state, action) {
			state.isLoading = false
			state.scripts = state.scripts.filter((e: IScript) => e.id !== action.payload)
		},

        setCurrentID(state, action) {
			state.currentID = action.payload
		},
	},
})

// Reducer
export default slice.reducer

// Actions
export const { setCurrentID } = slice.actions

export function getScripts(classification: boolean|null = null): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
			const filter = classification == null ? '' : `?classification=${(+ classification)}`
			const response = await axios.get(`/api/common/script${filter}`)
			dispatch(slice.actions.getScriptSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function createScript(data: IScript): Thunk<Promise<boolean>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post('/api/common/script/', data)
			return true
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			return false
		}
	}
}

export function saveScript(ID: string, data: IScript): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
            const response = await axios.put(`/api/common/script/${ID}/`, data)
			dispatch(slice.actions.saveScriptSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function deleteScript(ID: string): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
            await axios.delete(`/api/common/script/${ID}/`)
			dispatch(slice.actions.deleteScriptSuccess(ID))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
	return `${root}${sublink}`
}

const ROOTS_PATH = ''

// ----------------------------------------------------------------------

export const PATH_AUTH = {
	login: '/login',
}

export const PATH_PAGE = {
	root: ROOTS_PATH,
	chats: path(ROOTS_PATH, '/chats'),
	scripts: path(ROOTS_PATH, '/scripts'),
	classification_scripts: path(ROOTS_PATH, '/classification_scripts'),
	test_chats: path(ROOTS_PATH, '/test_chats'),
	settings: {
		root: path(ROOTS_PATH, '/settings'),
		general: path(ROOTS_PATH, '/settings/general'),
		crm: path(ROOTS_PATH, '/settings/crm'),
		chatgpt: path(ROOTS_PATH, '/settings/chatgpt'),
		meta: path(ROOTS_PATH, '/settings/meta'),
		telegram: path(ROOTS_PATH, '/settings/telegram'),
	},
	integrations: path(ROOTS_PATH, '/integrations'),
	roles: path(ROOTS_PATH, '/roles'),
	triggers: path(ROOTS_PATH, '/triggers'),
}

import { AnyAction, createSlice, Dispatch } from '@reduxjs/toolkit'
import { ISettingsState, ISetting, ISettingType } from 'src/@types/settings'
import axios from 'src/utils/axios'

const initialState: ISettingsState = {
	isLoading: false,
	error: null,
    settings: [],
}

const slice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

        getSettingsSuccess(state, action) {
			state.isLoading = false
			const settings = action.payload
			state.settings = settings
		},

		getSettingSuccess(state, action) {
			state.isLoading = false
			const setting = action.payload
			const settings = state.settings.filter((e: ISetting) => e.id !== setting.id)
			settings.push(setting)
			state.settings = settings
		},

	},
})

// Reducer
export default slice.reducer

// Actions
export const {
	getSettingSuccess
} = slice.actions

export function getSettings() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/common/setting/`)
			dispatch(slice.actions.getSettingsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getSetting(type: ISettingType) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/common/setting/by_type/?type=${type}`)
			dispatch(slice.actions.getSettingSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updateExtraSetting(setting: ISetting, data: any, successAction: (data:any) => AnyAction) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`/api/common/setting/${setting.id}/extra/`, data)
			dispatch(successAction(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updateParamsSetting(setting: ISetting, data: any, successAction: (data:any) => AnyAction) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`/api/common/setting/${setting.id}/params/`, data)
			dispatch(successAction(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

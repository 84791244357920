import React, { FC } from 'react'
// mui
import { AppBar, Stack, Typography } from '@mui/material'
// components
import AccountPopover from './AccountPopover'
// utils
import LanguagePopover from './LanguagePopover'

// ----------------------------------------------------------------------

const Header: FC = () => (
	<AppBar
		sx={{
			boxShadow: 0,
			px: 2,
			height: 56,
			display: 'flex',
			alignItems: 'center',
			backgroundColor: theme => theme.palette.grey[900],

			'& *': {
				color: theme => theme.palette.text.primary,
			},
		}}
	>
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			sx={{
				width: '100%',
				height: '100%',
			}}
		>
			<Stack direction='row' alignItems='center' gap={1}>
				<Typography
					variant='h3'
					sx={{
						pl: 2,
						cursor: 'default',
						color: theme => `${theme.palette.common.white} !important`,
					}}
				>
					RBS
				</Typography>
			</Stack>
			<Stack direction='row' alignItems='center' gap={1}>
				<Stack
					flexGrow={1}
					direction='row'
					alignItems='center'
					justifyContent='flex-end'
					spacing={{ xs: 0.5, sm: 1.5 }}
				>
					<LanguagePopover />

					<AccountPopover />
				</Stack>
			</Stack>
		</Stack>
	</AppBar>
)

export default Header

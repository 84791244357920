// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Stack, TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
    name: string;
};

export default function RHFTimestamp({ name, helperText, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value, onBlur, ref }, fieldState: { error } }) => {
                const seconds = value % 60
                const minutes = ((value - seconds) / 60) % 60
                const hours   = ((((value - seconds) / 60) - minutes) / 60) % 24
                const days    = (((((value - seconds) / 60) - minutes) / 60) - hours) / 24

                return <Stack spacing={1} direction='row' ref={ref} onBlur={onBlur}>
                    <TextField label="Seconds" onChange={
                        (event: any) => {
                            const r = parseInt(event.target.value, 10)
                            if (r) onChange((value - seconds) + r)
                            else onChange(value - seconds)
                        }
                    } value={seconds} />
                    <TextField label="Minutes" onChange={
                        (event: any) => {
                            onChange((value - (minutes * 60)) + ((parseInt(event.target.value, 10) || 0) * 60))
                        }
                    } value={minutes} />
                    <TextField label="Hours" onChange={
                        (event: any) => {
                            onChange((value - (hours * 60 * 60)) + ((parseInt(event.target.value, 10) || 0) * 60 * 60))
                        }
                    } value={hours} />
                    <TextField label="Days" onChange={
                        (event: any) => {
                            onChange((value - (days * 24 * 60 * 60)) + ((parseInt(event.target.value, 10) || 0) * 24 * 60 * 60))
                        }
                    } value={days} />
                </Stack>
            }
            }
        />
    );
}

import React, { ReactNode, createContext, useState, useContext, useEffect, useMemo, useCallback } from 'react'
import axios from 'src/utils/axios'
import { WS_ENDPOINT } from 'src/config-global'
import localStorageAvailable from '../utils/localStorageAvailable'
import WebSocketClient, { Status } from '../utils/websocket'
import { useAuthContext } from './useAuthContext'
import { getToken } from './utils'

type WSContextType = {
	ws: WebSocketClient | null
	isReady: boolean
	isAuthReady: boolean
	getMessages: (chat_uuid: string) => void
	sendMessage: (text: string, chat_uuid: string) => void
}

type Props = {
	children: ReactNode
}

const WSContext = createContext<WSContextType>({
	ws: null,
	isReady: false,
	isAuthReady: false,
	getMessages: (chat_uuid: string) => {},
	sendMessage: (text: string, chat_uuid: string) => {},
})

export const WSProvider = ({ children }: Props) => {
	const storageAvailable = localStorageAvailable()
	const token = getToken(storageAvailable)

	const [isReady, setIsReady] = useState<boolean>(false)
	const [isAuthReady, setIsAuthReady] = useState<boolean>(false)
	const [host, setHost] = useState<string | null>(null)
	const { isAuthenticated } = useAuthContext()

	const ws = useMemo(() => host ? new WebSocketClient(`${host}${WS_ENDPOINT}`) : null, [host])

	useEffect(() => {
		const getWsHost = async () => {
			const response = await axios.get('/api/ws/endpoint/')
			setHost(response.data)
		}
		if (token?.access && isAuthenticated) getWsHost()
	}, [token?.access, isAuthenticated])

	useEffect(() => {
		if (!ws) return
		ws.onStatusChanged = (status: number) => {
			if (status === Status.CLOSE) {
				setIsReady(false)
				setIsAuthReady(false)
			}
			else if (status === Status.OPEN) setIsReady(true)
		}
		ws.onAuthFailed = () => {
            if (token?.access) ws.sendAuth(token?.access)
        }
		ws.onAuthSuccess = () => {
			ws.whoiam()
			setIsAuthReady(true)
		}
	}, [ws, token?.access])

	useEffect(() => {
		if (!ws) return
		ws.connect()
	}, [ws])

	useEffect(() => () => ws?.reconnect(), [ws, isAuthenticated])

	const getMessages = useCallback((chat_uuid: string) => {
        ws?.sendData({
            request: "GET MESSAGES", data: {
				uuid: chat_uuid
			}
        })
    }, [ws])

	const sendMessage = useCallback((text: string, chat_uuid: string) => {
		ws?.sendData({
			request: "SEND MESSAGE", data: {
				uuid: chat_uuid,
				text
			}
		})
	}, [ws])

	const value = useMemo(
		() => ({
			ws,
			isReady,
			isAuthReady,
			getMessages,
			sendMessage,
		}),
		[
			ws,
			isReady,
			isAuthReady,
			getMessages,
			sendMessage
		]
	)

	return <WSContext.Provider value={value}>{children}</WSContext.Provider>
}

export const useWS = () => useContext(WSContext)

// routes
import { PATH_AUTH } from 'src/routes/paths'
// utils
import axios from 'src/utils/axios'
import localStorageAvailable from 'src/utils/localStorageAvailable'

// ----------------------------------------------------------------------

export type IJWT = {
	access: string
	refresh: string
}

// ----------------------------------------------------------------------

export function jwtDecode(token: string) {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
			.join('')
	)

	return JSON.parse(jsonPayload)
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
	if (!accessToken) {
		return false
	}

	const decoded = jwtDecode(accessToken)

	const currentTime = Date.now() / 1000

	return decoded.exp > currentTime
}

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
	// eslint-disable-next-line prefer-const
	let expiredTimer

	const currentTime = Date.now()

	// Test token expires after 10s
	// const timeLeft = currentTime + 10000 - currentTime; // ~10s
	const timeLeft = exp * 1000 - currentTime

	clearTimeout(expiredTimer)

	expiredTimer = setTimeout(async () => {
		const storageAvailable = localStorageAvailable()
		let token = null

		if (storageAvailable && localStorage.getItem('token')) {
			token = JSON.parse(localStorage.getItem('token') ?? '{"access": null, "refresh": null}')
		}

		if (token && token.refresh) {
			try {
				const res = await axios.post('api/token/refresh/', {
					refresh: token.refresh,
				})
				const { access, refresh } = res.data

				setSession({ access, refresh })
			} catch (_error) {
				console.log(_error)
				localStorage.removeItem('token')
				window.location.href = PATH_AUTH.login
			}
		} else {
			localStorage.removeItem('token')
			window.location.href = PATH_AUTH.login
		}
	}, timeLeft)
}

// ----------------------------------------------------------------------

export const setSession = (token: IJWT | null) => {
	if (token) {
		localStorage.setItem('token', JSON.stringify(token))

		axios.defaults.headers.common.Authorization = `Bearer ${token.access}`

		// This function below will handle when token is expired
		const { exp } = jwtDecode(token.access) // ~3 days by minimals server
		tokenExpired(exp)
	} else {
		localStorage.removeItem('token')

		delete axios.defaults.headers.common.Authorization
	}
}

// ----------------------------------------------------------------------
export const getToken = (storageAvailable: boolean): IJWT | null => {
	if (storageAvailable && localStorage.getItem('token')) {
		return JSON.parse(localStorage.getItem('token') ?? '{"access": null, "refresh": null}') as IJWT
	}

	return null
}

// @mui
import { Stack, Typography } from '@mui/material'

// layouts
import LoginLayout from 'src/layouts/login'
import { useLocales } from 'src/locales'
//
import AuthLoginForm from './AuthLoginForm'

// ----------------------------------------------------------------------

export default function Login() {
	const { translate } = useLocales()

	return (
		<LoginLayout>
			<Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
				<Stack direction='row' alignSelf='center'>
					<Typography variant='h4'>{`${translate('auth')}`}</Typography>
				</Stack>
			</Stack>

			<AuthLoginForm />
		</LoginLayout>
	)
}

import { createSlice } from '@reduxjs/toolkit'
import { IChat, IChatState, IChatValues } from 'src/@types/chat'
import axios from 'src/utils/axios'

const initialState: IChatState = {
	isLoading: false,
	error: null,
	chats: [],
	currentChatID: null,
	currentTestChatID: null,
}

const slice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		startLoading(state) {
			state.isLoading = true
		},

		stopLoading(state) {
			state.isLoading = false
		},

		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getChatsSuccess(state, action) {
			state.isLoading = false
			const chats = action.payload
			state.chats = chats
		},

		getChatSuccess(state, action) {
			state.isLoading = false
			state.chats = state.chats.map((e: IChat) =>
				e.id === action.payload.id ? action.payload : e
			)
		},

		setCurrentChatID(state, action) {
			state.currentChatID = action.payload
		},

		setCurrentTestChatID(state, action) {
			state.currentTestChatID = action.payload
		},

		deleteChatSuccess(state, action) {
			state.isLoading = false
			state.chats = state.chats.filter((e: IChat) => e.id !== action.payload)
		},
	},
})

// Reducer
export default slice.reducer

// Actions
export const { setCurrentChatID, setCurrentTestChatID } = slice.actions

export function getChats(isTest: boolean = false, startLoading: boolean = true): Thunk<Promise<void>> {
	return async dispatch => {
		if (startLoading) dispatch(slice.actions.startLoading())
		try {
			let response = null
			if (isTest) response = await axios.get(`/api/common/chat?is_test=1`)
			else response = await axios.get(`/api/common/chat/`)
			dispatch(slice.actions.getChatsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function createChat(chatData: IChatValues): Thunk<Promise<boolean>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post('/api/common/chat/', chatData)
			dispatch(getChats(true))
			return true
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			return false
		}
	}
}

export function updateChat(chatData: IChat): Thunk<Promise<boolean>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`/api/common/chat/${chatData.id}/`, chatData)
			dispatch(slice.actions.getChatSuccess(response.data))
			return true
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			return false
		}
	}
}

export function deleteChat(ID: string): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
            await axios.delete(`/api/common/chat/${ID}/`)
			dispatch(slice.actions.deleteChatSuccess(ID))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

// @mui
import { Stack } from '@mui/material'
// components
import { StyledRoot, StyledContent } from './styles'

// ----------------------------------------------------------------------

type Props = {
	children: React.ReactNode
}

export default function LoginLayout({ children }: Props) {
	return (
		<StyledRoot>
			<StyledContent>
				<Stack sx={{ width: 1 }}>{children}</Stack>
			</StyledContent>
		</StyledRoot>
	)
}

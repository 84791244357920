// routes
import { PATH_PAGE } from 'src/routes/paths'
// components
import SvgColor from 'src/components/svg-color'

// ----------------------------------------------------------------------

const icon = (name: string) => (
	<SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
)

const ICONS = {
	chats: icon('ic_chat'),
	scripts: icon('ic_script'),
	test_chat: icon('ic_test_chat'),
	settings: icon('ic_settings'),
	integrations: icon('ic_integration'),
	roles: icon('ic_role'),
	trigger: icon('ic_trigger'),
}

const navConfig = [
	// GENERAL
	// ----------------------------------------------------------------------
	{
		subheader: '',
		items: [
			{ title: 'chats', path: PATH_PAGE.chats, icon: ICONS.chats },
			{ title: 'scripts.root', path: PATH_PAGE.scripts, icon: ICONS.scripts },
			{ title: 'scripts.classification', path: PATH_PAGE.classification_scripts, icon: ICONS.scripts },
			{ title: 'test_chats.root', path: PATH_PAGE.test_chats, icon: ICONS.test_chat },
			// { title: 'integrations.root', path: PATH_PAGE.integrations, icon: ICONS.integrations },
			{
				title: 'settings.root', path: PATH_PAGE.settings.root, icon: ICONS.settings, children: [
					{ title: 'settings.general', path: PATH_PAGE.settings.general },
					{ title: 'settings.crm', path: PATH_PAGE.settings.crm },
					{ title: 'settings.chatgpt', path: PATH_PAGE.settings.chatgpt },
					{ title: 'settings.meta', path: PATH_PAGE.settings.meta },
					{ title: 'settings.telegram', path: PATH_PAGE.settings.telegram },
				]
			},
			{ title: 'triggers.root', path: PATH_PAGE.triggers, icon: ICONS.trigger },
			// { title: 'roles.root', path: PATH_PAGE.roles, icon: ICONS.roles },
		],
	},
]

export default navConfig

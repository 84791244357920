import { useState } from 'react'
// @mui
import { Box, Drawer, Stack } from '@mui/material'
// config
import { NAV } from 'src/config-global'
// components
import Scrollbar from 'src/components/scrollbar'
import useLocalStorage from 'src/hooks/useLocalStorage'
import { NavSectionVertical } from 'src/components/nav-section'
//
import navConfig from './config-navigation'
// ----------------------------------------------------------------------

export default function NavVertical() {
	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				'& .simplebar-content': {
					height: 1,
					display: 'flex',
					flexDirection: 'column',
					zIndex: 0,
				},
			}}
		>
			<NavSectionVertical data={navConfig} />
			<Box sx={{ flexGrow: 1 }} />
		</Scrollbar>
	)

	const [userSettings, setUserSettings] = useLocalStorage('userPreferences', {
		navWidth: NAV.W_MAIN
	});

	const handleMouseMove = (event: any) => {
		setUserSettings((prev: any) => ({ ...prev, navWidth: event.clientX }))
	}

	const stopResizing = (event: any) => {
		window.removeEventListener('mousemove', handleMouseMove)
		window.removeEventListener("mouseup", stopResizing);
	}

	const handleMouseDown = (event: any) => {
		window.addEventListener('mousemove', handleMouseMove)
		window.addEventListener("mouseup", stopResizing);
	}

	return (
		<>
			<Box
				component='nav'
				sx={{
					flexShrink: 0,
					width: userSettings.navWidth,
				}}
			>
				<Drawer
					open
					variant='permanent'
					PaperProps={{
						sx: {
							zIndex: 10,
							height: 'calc(100% - 56px)',
							bottom: 0,
							borderRight: 'unset',
							top: 'unset',
							width: userSettings.navWidth,
							py: 2,
							background: theme => theme.palette.grey[700],
						},
					}}
				>
					{renderContent}
				</Drawer>
			</Box>
			<Stack sx={{
				position: 'absolute',
				left: userSettings.navWidth - 8,
				zIndex: 15,
				height: '100%',
				width: '20px', '&:hover': {
					cursor: 'w-resize'
				},
				background: theme => theme.palette.primary.main,
				backgroundColor: 'transparent',
			}} onMouseDown={handleMouseDown} />
		</>
	)
}

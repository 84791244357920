import axios from 'axios'
// config
import { BASE_URL } from '../config-global'
import localStorageAvailable from './localStorageAvailable'

const axiosInstance = axios.create({ baseURL: BASE_URL })

axiosInstance.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axiosInstance.defaults.xsrfCookieName = 'csrftoken'

axiosInstance.interceptors.response.use(
	response => response,
	async error => {
		const originalConfig = error.config

		if (error.code === 'ERR_CANCELED') {
			return Promise.reject(error)
		}

		if (originalConfig.url !== '/login' && error.response) {
			if (error.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true

				const storageAvailable = localStorageAvailable()
				let token = null

				if (storageAvailable && localStorage.getItem('token')) {
					token = JSON.parse(localStorage.getItem('token') ?? '{"access": null, "refresh": null}')
				}

				if (token && token.refresh) {
					try {
						const rs = await axiosInstance.post('api/token/refresh/', {
							refresh: token.refresh,
						})

						const { access, refresh } = rs.data
						localStorage.setItem('token', JSON.stringify({access, refresh}))
						axiosInstance.defaults.headers.common.Authorization = `Bearer ${token.access}`

						return await axiosInstance(originalConfig)
					} catch (_error) {
						return Promise.reject(_error)
					}
				} else {
					return Promise.reject(error)
				}
			}
		}
		return Promise.reject(error)
	}
)

export default axiosInstance

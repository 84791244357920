import { createSlice } from '@reduxjs/toolkit'
import { IPipeline, IPipelineSlice, IPipelineStatus } from 'src/@types/pipelines'
import axios from 'src/utils/axios'

const initialState: IPipelineSlice = {
	isLoading: false,
	error: null,
    pipelines: [],
	pipeline_statuses: [],
}

const slice = createSlice({
	name: 'pipelines',
	initialState,
	reducers: {
		startLoading(state) {
			state.isLoading = true
		},

		stopLoading(state) {
			state.isLoading = false
		},

		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getPipelinesSuccess(state, action) {
			state.isLoading = false
			state.pipelines = action.payload
		},

		getPipelineSuccess(state, action) {
			state.isLoading = false
			state.pipelines = state.pipelines.map((e: IPipeline) =>
				e.id === action.payload.id ? action.payload : e
			)
		},

		getPipelineStatusesSuccess(state, action) {
			state.isLoading = false
			state.pipeline_statuses = action.payload
		},

		getStatusSuccess(state, action) {
			state.isLoading = false
			state.pipelines = state.pipelines.map((e: IPipeline) =>
				e.id === action.payload.pipeline.id ? {...e, statuses: [...e.statuses.filter((s: IPipelineStatus) => s.id !== action.payload.id), action.payload]} : e
			)
		},
	},
})

// Reducer
export default slice.reducer

// Actions
// export const {} = slice.actions

export function getPipelines(): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/common/pipeline/`)
			dispatch(slice.actions.getPipelinesSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updatePipeline(pipeline: IPipeline, data: any): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`/api/common/pipeline/${pipeline.id}/`, data)
			dispatch(slice.actions.getPipelineSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getPipelineStatuses(): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/common/pipeline_status/`)
			dispatch(slice.actions.getPipelineStatusesSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function setActivePipelineStatus(id: string, active: boolean): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`/api/common/pipeline_status/${id}/`, {active})
			dispatch(slice.actions.getStatusSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

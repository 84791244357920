// @mui
import { Box, BoxProps } from '@mui/material'
// config
import { HEADER_CUSTOM } from '../../config-global'

// ----------------------------------------------------------------------

export default function Main({ children }: BoxProps) {
	return (
		<Box
			component='main'
			sx={{
				pt: `${HEADER_CUSTOM.HEIGHT}px`,
				width: '100%',
				maxHeight: '100%',
				height: '100%',
				flex: 1,
			}}
		>
			{children}
		</Box>
	)
}

import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react'
// utils
import axios from 'src/utils/axios'
import localStorageAvailable from 'src/utils/localStorageAvailable'
import { store } from 'src/redux/store'
//
import { isValidToken, setSession, jwtDecode, IJWT, getToken } from './utils'
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types'

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
	INITIAL = 'INITIAL',
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
}

type Payload = {
	[Types.INITIAL]: {
		isAuthenticated: boolean
		user: AuthUserType
	}
	[Types.LOGIN]: {
		user: AuthUserType
	}
	[Types.LOGOUT]: undefined
}

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>]

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
	isInitialized: false,
	isAuthenticated: false,
	user: null,
}

const reducer = (state: AuthStateType, action: ActionsType) => {
	if (action.type === Types.INITIAL) {
		return {
			isInitialized: true,
			isAuthenticated: action.payload.isAuthenticated,
			user: action.payload.user,
		}
	}
	if (action.type === Types.LOGIN) {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		}
	}
	if (action.type === Types.LOGOUT) {
		return {
			...state,
			isAuthenticated: false,
			user: null,
		}
	}
	return state
}

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null)

// ----------------------------------------------------------------------

type AuthProviderProps = {
	children: React.ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
	const [state, dispatch] = useReducer(reducer, initialState)

	const storageAvailable = localStorageAvailable()

	const initialize = useCallback(async () => {
		try {
			const token = getToken(storageAvailable)

			if (token && isValidToken(token.access)) {
				setSession(token)

				const { user_id, username } = jwtDecode(token.access)

				const user = {
					id: user_id,
					username,
				}

				dispatch({
					type: Types.INITIAL,
					payload: {
						isAuthenticated: true,
						user,
					},
				})
			} else if (token && isValidToken(token.refresh)) {
				const rs = await axios.post('api/token/refresh/', {
					refresh: token.refresh,
				})
				const { access, refresh } = rs.data
				setSession({access, refresh})

				const { user_id, username } = jwtDecode(access)

				const user = {
					id: user_id,
					username,
				}

				dispatch({
					type: Types.INITIAL,
					payload: {
						isAuthenticated: true,
						user,
					},
				})
			} else {
				dispatch({
					type: Types.INITIAL,
					payload: {
						isAuthenticated: false,
						user: null,
					},
				})
			}
		} catch (error) {
			console.error(error)
			dispatch({
				type: Types.INITIAL,
				payload: {
					isAuthenticated: false,
					user: null,
				},
			})
		}
	}, [storageAvailable])

	useEffect(() => {
		initialize()
	}, [initialize])

	// LOGIN
	const login = useCallback(async (username: string, password: string) => {
		const response = await axios.post('/api/token/', {
			username,
			password,
		})
		const { access, refresh } = response.data
		const { user_id, username: name } = jwtDecode(access)
		setSession({ access, refresh } as IJWT)

		dispatch({
			type: Types.LOGIN,
			payload: {
				user: {
					id: user_id,
					username: name,
				},
			},
		})
	}, [])

	// LOGOUT
	const logout = useCallback(() => {
		setSession(null)
		dispatch({
			type: Types.LOGOUT,
		})
		store.dispatch({ type: 'DESTROY_SESSION' })
	}, [])

	const memoizedValue = useMemo(
		() => ({
			isInitialized: state.isInitialized,
			isAuthenticated: state.isAuthenticated,
			user: state.user,
			method: 'jwt',
			login,
			logout,
		}),
		[state.isAuthenticated, state.isInitialized, state.user, login, logout]
	)

	return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}

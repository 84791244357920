import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { ITrigger, ITriggersState } from 'src/@types/triggers'
import axios from 'src/utils/axios'

const initialState: ITriggersState = {
	isLoading: false,
	error: null,
    triggers: [],
	selectedTriggerID: null,
	trigger: null,
	eventTypes: [],
	actionTypes: [],
	conditionTypes: [],
}

const slice = createSlice({
	name: 'triggers',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		setSelectedTriggerID(state, action) {
			state.selectedTriggerID = action.payload
		},

        getTriggersSuccess(state, action) {
			state.isLoading = false
			state.triggers = action.payload
		},

		getTriggerSuccess(state, action) {
			state.isLoading = false
			state.trigger = action.payload
		},

		deleteTriggerSuccess(state, action) {
			state.isLoading = false
			state.triggers = state.triggers.filter((e: ITrigger) => e.id !== action.payload)
		},

		cleanTrigger(state) {
			state.isLoading = false
			state.trigger = null
		},

		getEventTypesSuccess(state, action) {
			state.eventTypes = action.payload
		},

		getActionTypesSuccess(state, action) {
			state.actionTypes = action.payload
		},

		getConditionTypesSuccess(state, action) {
			state.conditionTypes = action.payload
		},
	},
})

// Reducer
export default slice.reducer

// Actions
export const {
	getTriggersSuccess
} = slice.actions

export function getTriggers() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/common/trigger/`)
			dispatch(slice.actions.getTriggersSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getTrigger(id: string): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.cleanTrigger())
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/common/trigger/${id}/`)
			dispatch(slice.actions.getTriggerSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updateTrigger(id: string, data: object): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.cleanTrigger())
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`/api/common/trigger/${id}/`, data)
			dispatch(slice.actions.getTriggerSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function deleteTrigger(id: string): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.cleanTrigger())
		dispatch(slice.actions.startLoading())
		try {
			await axios.delete(`/api/common/trigger/${id}/`)
			dispatch(slice.actions.deleteTriggerSuccess(id))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function createTrigger(data: object): Thunk<Promise<ITrigger>> {
	return async dispatch => {
		dispatch(slice.actions.cleanTrigger())
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.post(`/api/common/trigger/`, data)
			dispatch(slice.actions.getTriggerSuccess(response.data))
			return response.data
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			return null
		}
	}
}

export function getEventTypes(): Thunk<Promise<void>> {
	return async dispatch => {
		try {
			const response = await axios.get(`/api/common/trigger/get_event_types/`)
			dispatch(slice.actions.getEventTypesSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getActionTypes(): Thunk<Promise<void>> {
	return async dispatch => {
		try {
			const response = await axios.get(`/api/common/trigger/get_action_types/`)
			dispatch(slice.actions.getActionTypesSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getConditionTypes(): Thunk<Promise<void>> {
	return async dispatch => {
		try {
			const response = await axios.get(`/api/common/trigger/get_condition_types/`)
			dispatch(slice.actions.getConditionTypesSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

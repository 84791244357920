import { Suspense, lazy, ElementType } from 'react'
// components
import LoadingScreen from '../components/loading-screen'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
	(
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	)

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')))

export const Chats = Loadable(lazy(() => import('../pages/Chats')))
export const Scripts = Loadable(lazy(() => import('../pages/Scripts')))
export const ClassificationScripts = Loadable(lazy(() => import('../pages/ClassificationScripts')))
export const TestChats = Loadable(lazy(() => import('../pages/TestChats')))
export const Settings = Loadable(lazy(() => import('../pages/Settings')))
export const Integrations = Loadable(lazy(() => import('../pages/Integrations')))

export const TabMain = Loadable(lazy(() => import('../sections/settings/tabs/Main')))
export const TabCRM = Loadable(lazy(() => import('../sections/settings/tabs/CRM')))
export const TabChatGpt = Loadable(lazy(() => import('../sections/settings/tabs/ChatGpt')))
export const TabMeta = Loadable(lazy(() => import('../sections/settings/tabs/Meta')))
export const TabTelegram = Loadable(lazy(() => import('../sections/settings/tabs/TelegramBot')))

export const Roles = Loadable(lazy(() => import('../pages/Roles/RoleList')))
export const RoleForm = Loadable(lazy(() => import('../pages/Roles/RoleForm')))

export const Triggers = Loadable(lazy(() => import('../pages/Triggers/TriggerList')))
export const TriggerForm = Loadable(lazy(() => import('../pages/Triggers/TriggerForm')))

export const Page404 = Loadable(lazy(() => import('../pages/Page404')))

import { combineReducers } from 'redux'

import storage from 'redux-persist/lib/storage'
// slices
import chatsReducer from './slices/chat'
import settingsReducer from './slices/settings'
import scriptsReducer from './slices/script'
import rolesReducer from './slices/roles'
import pipelinesReducer from './slices/pipelines'
import triggersReducer from './slices/triggers'
// ----------------------------------------------------------------------

export const rootPersistConfig = {
	key: 'root',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const appReducer = combineReducers({
	chats: chatsReducer,
	settings: settingsReducer,
	scripts: scriptsReducer,
	roles: rolesReducer,
	pipelines: pipelinesReducer,
	triggers: triggersReducer,
})

const rootReducer = (state: any, action: any) => {
	if(action.type === 'DESTROY_SESSION') state = undefined
	return appReducer(state, action);
};

export default rootReducer

import { createSlice } from '@reduxjs/toolkit'
import { IRolesSlice } from 'src/@types/roles'
import axios from 'src/utils/axios'

const initialState: IRolesSlice = {
	isLoading: false,
	error: null,
    roles: [],
	selectedRoleID: null,
	role: null,
}

const slice = createSlice({
	name: 'roles',
	initialState,
	reducers: {
		startLoading(state) {
			state.isLoading = true
		},

		stopLoading(state) {
			state.isLoading = false
		},

		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		setSelectedRoleID(state, action) {
			state.selectedRoleID = action.payload
		},

		getRolesSuccess(state, action) {
			state.isLoading = false
			state.roles = action.payload
		},

		getRoleSuccess(state, action) {
			state.isLoading = false
			state.role = action.payload
		},

		cleanRole(state) {
			state.isLoading = false
			state.role = null
		}
	},
})

// Reducer
export default slice.reducer

// Actions
export const { setSelectedRoleID } = slice.actions

export function getRoles(): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/common/role/`)
			dispatch(slice.actions.getRolesSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getRole(id: string): Thunk<Promise<void>> {
	return async dispatch => {
		dispatch(slice.actions.cleanRole())
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/common/role/${id}/`)
			dispatch(slice.actions.getRoleSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

import React from 'react'
// @mui
import { MenuItem, Stack, Typography } from '@mui/material'
// locales

import Select from '@mui/material/Select'
import { useLocales } from 'src/locales'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

// ----------------------------------------------------------------------
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
}

// ----------------------------------------------------------------------

export default function LanguageSelect() {
	const { allLangs, currentLang, onChangeLang, translate } = useLocales()

	const handleChange = (event: any) => {
		const {
			target: { value },
		} = event

		onChangeLang(value)
	}

	return (
		<Stack direction='row' alignItems='center' gap={2}>
			<Typography>{`${translate('lang')}`}:</Typography>
			<Select
				labelId='demo-multiple-name-label'
				id='demo-multiple-name'
				value={currentLang.value}
				onChange={handleChange}
				fullWidth
				MenuProps={MenuProps}
			>
				{allLangs.map(lang => (
					<MenuItem key={lang.value} value={lang.value}>
						{lang.label}
					</MenuItem>
				))}
			</Select>
		</Stack>
	)
}

import React from 'react'
import { Outlet } from 'react-router-dom'
// @mui
import { Box } from '@mui/material'
// components
import Main from './Main'
import Header from './header'
import NavVertical from './nav/NavVertical'

// ----------------------------------------------------------------------

export default function DashboardLayout() {
	const renderNavVertical = <NavVertical />

	return (
		<>
			<Header />

			<Box
				sx={{
					display: 'flex',
					minHeight: 1,
					maxHeight: '100vh',
					height: '100%',
				}}
			>
				{renderNavVertical}

				<Main>
					<Outlet />
				</Main>
			</Box>
		</>
	)
}

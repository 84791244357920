// routes
import { PATH_PAGE } from './routes/paths'

// API
// ----------------------------------------------------------------------

export const BASE_URL = process.env.REACT_APP_BASE_URL ?? '/'
export const WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT ?? ''

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_PAGE.chats

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER_CUSTOM = {
	HEIGHT: 57,
	BREADCRUMBS_H: 68,
}

export const HEADER = {
	H_MOBILE: 64,
	H_MAIN_DESKTOP: 88,
	H_DASHBOARD_DESKTOP: 92,
	H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
}

export const NAV = {
	W_BASE: 260,
	W_DASHBOARD: 280,
	W_DASHBOARD_MINI: 64,
	W_MAIN: 278,
	//
	H_DASHBOARD_ITEM: 32,
	H_DASHBOARD_ITEM_SUB: 36,
	//
	H_DASHBOARD_ITEM_HORIZONTAL: 32,
}

export const ICON = {
	NAV_ITEM: 26,
	NAV_ITEM_HORIZONTAL: 22,
	NAV_ITEM_MINI: 22,
}

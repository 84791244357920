import { Navigate, useRoutes } from 'react-router-dom'
// auth
import AuthGuard from '../auth/AuthGuard'
import GuestGuard from '../auth/GuestGuard'
// layouts
import CompactLayout from '../layouts/compact'
import DashboardLayout from '../layouts/dashboard'
// config
import { PATH_AFTER_LOGIN } from '../config-global'
//
import {
	Page404,
	Chats,
	Scripts,
	ClassificationScripts,
	LoginPage,
	TestChats,
	Settings,
	Integrations,
	Roles,
	RoleForm,
	Triggers,
	TriggerForm,
	TabMain,
	TabCRM,
	TabChatGpt,
	TabMeta,
	TabTelegram
} from './elements'
// ----------------------------------------------------------------------

export default function Router() {
	return useRoutes([
		{
			path: '/',
			children: [
				{
					path: 'login',
					element: (
						<GuestGuard>
							<LoginPage />
						</GuestGuard>
					),
				},
			],
		},
		{
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [
				{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
				{ path: 'chats', element: <Chats /> },
				{ path: 'scripts', element: <Scripts /> },
				{ path: 'classification_scripts', element: <ClassificationScripts /> },
				{ path: 'test_chats', element: <TestChats /> },
				{ path: 'integrations', element: <Integrations /> },
				{
					path: 'settings', children: [
						{ path: 'general', element: <Settings><TabMain /></Settings> },
						{ path: 'crm', element: <Settings><TabCRM /></Settings> },
						{ path: 'chatgpt', element: <Settings><TabChatGpt /></Settings> },
						{ path: 'meta', element: <Settings><TabMeta /></Settings> },
						{ path: 'telegram', element: <Settings><TabTelegram /></Settings> },
					]
				},
				{
					path: 'roles', children: [
						{ element: <Roles />, index: true },
						{ path: 'new', element: <RoleForm create={1} /> },
						{ path: ':id', element: <RoleForm /> },
					]
				},
				{
					path: 'triggers', children: [
						{ element: <Triggers />, index: true },
						{ path: 'new', element: <TriggerForm create={1} /> },
						{ path: ':id', element: <TriggerForm /> },
					]
				},
			],
		},
		{
			element: <CompactLayout />,
			children: [{ path: '404', element: <Page404 /> }],
		},
		{ path: '*', element: <Navigate to='/404' replace /> },
	])
}

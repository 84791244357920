// @mui
import { styled } from '@mui/material/styles'
import { ListItemIcon, ListSubheader, ListItemButton } from '@mui/material'
// config
import { ICON, NAV } from '../../../config-global'
//
import { NavItemProps } from '../types'

// ----------------------------------------------------------------------

type StyledItemProps = Omit<NavItemProps, 'item'> & {
	caption?: boolean
	disabled?: boolean
}

export const StyledItem = styled(ListItemButton, {
	shouldForwardProp: prop => prop !== 'active' && prop !== 'caption',
})<StyledItemProps>(({ active, theme }) => {
	const activeStyle = {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.grey[600],
	}

	return {
		position: 'relative',
		textTransform: 'none',
		marginBottom: theme.spacing(0.5),
		color: theme.palette.common.white,
		height: NAV.H_DASHBOARD_ITEM,
		// Active item
		...(active && {
			...activeStyle,
			'&:hover': {
				...activeStyle,
			},
		}),
	}
})

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	maxWidth: ICON.NAV_ITEM,
	maxHeight: ICON.NAV_ITEM,
	width: ICON.NAV_ITEM,
	height: ICON.NAV_ITEM,
})

// ----------------------------------------------------------------------

type StyledDotIconProps = {
	active?: boolean
}

export const StyledDotIcon = styled('span', {
	shouldForwardProp: prop => prop !== 'active',
})<StyledDotIconProps>(({ active, theme }) => ({
	width: 4,
	height: 4,
	borderRadius: '50%',
	backgroundColor: theme.palette.text.disabled,
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shorter,
	}),
	...(active && {
		transform: 'scale(2)',
		backgroundColor: theme.palette.primary.main,
	}),
}))

// ----------------------------------------------------------------------

export const StyledSubheader = styled(ListSubheader)(({ theme }) => ({
	...theme.typography.overline,
	fontSize: 11,
	paddingTop: theme.spacing(3),
	paddingBottom: theme.spacing(1),
	color: theme.palette.text.secondary,
}))

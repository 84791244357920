// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ru = {
	settings: {
		crm: 'CRM',
		account_id: 'Account ID',
		channel_id: 'Channel ID',
		title: 'Title',
		secret: 'Secret',
		root: 'Настройки',
		login: 'Логин',
		password: 'Пароль',
		token: 'Токен',
		send: 'Отправлять ответ в CRM',
		debug: 'Отладка',
		webhook: 'Webhook',
		bot_amojo_id: 'Bot Amojo ID',
		domain: 'Домен',
		redirect_uri: 'Redirect URI',
		client_id: 'Client ID',
		client_secret: 'Client Secret',
		telegram_token_bot: 'Telegram Bot Token',
		authorize_code: 'Код авторизации',
		pipeline_name: 'Воронка',
		pipeline_stages: 'Этап воронки',
		lead_status: 'Статусы лида',
		pipelines: 'Воронки',
		main: 'Main',
		general: 'Основные',
		chatgpt: 'ChatGPT',
		meta: 'Meta',
		telegram: 'Telegram',
	},
	chatsPage: {
		chats: 'Чаты',
		chatsView: 'Просмотр чата',
		client: 'Клиент',
		status: 'Статус',
		more: 'Загрузить еще',
	},
	in_progress: 'В процессе',
	success: 'Успешно',
	fail: 'Неудачно',
	authorization: 'Авторизация',
	login: 'Войти',
	logout: 'Выйти',
	chats: 'Чаты',
	scripts: {
		root: 'Скрипты',
		script: 'Скрипт',
		scriptEdit: 'Редактирование скрипта',
		status: 'Статус',
		import: 'Импорт',
		export: 'Экспорт',
		add: 'Создать скрипт',
		validations: {
			name: 'Введите наименование скрипта',
		},
		created: 'Скрипт успешно создан',
		create_error: 'Ошибка при создании скрипта',
		error_only_active_script: 'Только один скрипт может быть активным',
		system: 'Система',
		user: 'Пользователь',
		assistant: 'Ассистент',
		pipelines: 'Воронки',
		classification: 'Классификация',
	},
	test_chats: {
		root: 'Тест чатов',
		chat: 'Чат',
		status: 'Статус',
		send: 'Отправить',
		add: 'Создать чат',
		validations: {
			name: 'Введите наименование чата',
		},
		chat_created: 'Чат успешно создан',
		chat_create_error: 'Ошибка при создании чата',
		script: 'Скрипт',
	},
	integrations: {
		root: 'Интеграции',
		addChannel: 'Добавьте интеграцию',
		channel:
			'Интеграция — это аккаунт в мессенджере или соцсети, который вы подключаете, чтобы общаться с клиентами. ',
		chooseIntegration: 'Выберите, что хотите подключить:',

		telegram: {
			personal: 'Подключение любого аккаунта Telegram с возможностью писать первым.',
			bot: 'Подключение бота Telegram с возможностью общаться с клиентами, которые написали вам первыми.',
		},

		meta: {
			signin: 'Для добавления интеграции авторизуйтесь в аккаунте Facebook',
		},

		whatsapp: {
			default: 'Подключение любого аккаунта WhatsApp за минуту с возможностью писать первым.',
			waba: 'Официальный способ подключения бизнес-аккаунтов WhatsApp с возможностью делать рассылки.',
		},
	},
	roles: {
		root: 'Роли',
		create_role: 'Создать роль',
		name: 'Название роли',
		comment: 'Комментарий',
		updated_at: 'Дата изменения',
	},
	triggers: {
		active: 'Активно',
		root: 'Триггеры',
		name: 'Название',
		updated_at: 'Дата изменения',
		create_trigger: 'Создать триггер',
		execute_after: 'Выполнить после',
		conditions: 'Условия',
	},
	chat_name: 'Название чата',
	script_name: 'Название скрипта',
	remove: 'Удалить',
	edit: 'Изменить',
	add: 'Добавить',
	save: 'Сохранить',
	create: 'Создать',
	selected: 'выбрано',
	cancel: 'Отмена',
	removeTitle: 'Вы действительно хотите удалить запись <strong>{{name}}</strong>?',
	removesTitle: 'Вы действительно хотите удалить <strong>{{count}}</strong> записи?',
	message_send: 'Отправить',
	lang: 'Язык',
	auth: 'Авторизация',
	loginOrEmail: 'Логин или почта',
	password: 'Пароль',
	not_found: 'Страница не найдена',
	not_found_title: 'Извините, страница не найдена!',
	not_found_message:
		'Извините, мы не смогли найти страницу, которую вы ищете. Возможно, вы ошиблись адресом? Обязательно проверьте правописание.',
	not_found_button: 'Вернуться на главную',
	no_data: 'Нет данных',
	create_chat_in_crm: 'Создать чат в CRM',
	types: {
		new_tag: "Новый тег",
		move_lead: "Перевести сделку",
		create_task: "Выставить задачу",
		respond_user_message: "Направлен ответ пользователю",
		send_message_to_user: "Отправить сообщение пользователю",
		user_didnt_respond_to_message: "Пользователь не ответил на сообщение",
	}
}

export default ru

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @mui
import { Box, Divider, Typography, MenuItem } from '@mui/material'
// routes
import { PATH_AUTH } from 'src/routes/paths'
// auth
import { useAuthContext } from 'src/auth/useAuthContext'
// components
import { useSnackbar } from 'src/components/snackbar'
import MenuPopover from 'src/components/menu-popover'
import { IconButtonAnimate } from 'src/components/animate'
import SvgColor from 'src/components/svg-color'
import { useLocales } from 'src/locales'

// ----------------------------------------------------------------------

export default function AccountPopover() {
	const { translate } = useLocales()

	const navigate = useNavigate()

	const { user, logout } = useAuthContext()

	const { enqueueSnackbar } = useSnackbar()

	const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)

	const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
		setOpenPopover(event.currentTarget)
	}

	const handleClosePopover = () => {
		setOpenPopover(null)
	}

	const handleLogout = async () => {
		try {
			logout()
			navigate(PATH_AUTH.login, { replace: true })
			handleClosePopover()
		} catch (error) {
			console.error(error)
			enqueueSnackbar('Unable to logout!', { variant: 'error' })
		}
	}

	return (
		<>
			<IconButtonAnimate
				onClick={handleOpenPopover}
				sx={{
					p: 0,
					...(openPopover && {
						'&:before': {
							zIndex: 1,
							content: "''",
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							position: 'absolute',
						},
					}),
				}}
			>
				<SvgColor
					src='/assets/icons/navbar/ic_avatar.svg'
					sx={{ width: 34, height: 34, color: theme => theme.palette.common.white }}
				/>
			</IconButtonAnimate>

			<MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant='subtitle2' noWrap>
						{user?.username}
					</Typography>
				</Box>

				<Divider sx={{ borderStyle: 'dashed' }} />

				<MenuItem onClick={handleLogout} sx={{ m: 1 }}>
					{`${translate('logout')}`}
				</MenuItem>
			</MenuPopover>
		</>
	)
}

// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
	settings: {
		crm: 'CRM',
		account_id: 'Account ID',
		channel_id: 'Channel ID',
		title: 'Title',
		secret: 'Secret',
		root: 'Settings',
		login: 'Login',
		password: 'Password',
		token: 'Token',
		send: 'Send answers to CRM',
		debug: 'Debug',
		webhook: 'Webhook',
		bot_amojo_id: 'Bot Amojo ID',
		domain: 'Domain',
		redirect_uri: 'Redirect URI',
		client_id: 'Client ID',
		client_secret: 'Client Secret',
		telegram_token_bot: 'Telegram Bot Token',
		authorize_code: 'Authorize code',
		pipeline_name: 'Pipeline',
		pipeline_stages: 'Pipeline stages',
		lead_status: 'Lead statuses',
		pipelines: 'Pipelines',
		main: 'Main',
		general: 'General',
		chatgpt: 'ChatGPT',
		meta: 'Meta',
		telegram: 'Telegram',
	},
	chatsPage: {
		chats: 'Chats',
		chatsView: 'Chats View',
		client: 'Client',
		status: 'Status',
		more: 'More',
	},
	in_progress: 'In progress',
	success: 'Success',
	fail: 'Fail',
	authorization: 'Authorization',
	login: 'Login',
	logout: 'Logout',
	chats: 'Chats',
	scripts: {
		root: 'Scripts',
		script: 'Script',
		scriptEdit: 'Script Edit',
		status: 'Status',
		import: 'Import',
		export: 'Export',
		add: 'Create script',
		validations: {
			name: "Enter the script's name",
		},
		created: 'Script successfully created',
		create_error: 'Error creating script',
		error_only_active_script: 'You can only have one active script',
		system: 'System',
		user: 'User',
		assistant: 'Assistant',
		pipelines: 'Pipelines',
		classification: 'Сlassification',
	},
	test_chats: {
		root: 'Test chats',
		chat: 'Chat',
		status: 'Status',
		add: 'Create chat',
		validations: {
			name: "Enter the chat's name",
		},
		chat_created: 'Chat successfully created',
		chat_create_error: 'Error creating chat',
		script: 'Script',
	},
	integrations: {
		root: 'Integrations',
		addChannel: 'Add Integration',
		channel:
			'An integration is an account in a messenger or social network that you connect to communicate with customers.',
		chooseIntegration: 'Choose what you want to connect:',

		telegram: {
			personal: 'Connecting any Telegram account with the ability to write first.',
			bot: 'Connecting a Telegram bot with the ability to communicate with customers who wrote to you first.',
		},

		meta: {
			signin: 'Для добавления интеграции авторизуйтесь в аккаунте Facebook',
		},

		whatsapp: {
			default: 'Connect any WhatsApp account in a minute with the ability to write first.',
			waba: 'The official way to connect WhatsApp business accounts with the ability to make mailings.',
		},
	},
	roles: {
		root: 'Roles',
		create_role: 'Create role',
		name: 'Name',
		comment: 'Comment',
		updated_at: 'Updated At',
	},
	triggers: {
		active: 'Active',
		root: 'Triggers',
		name: 'Name',
		updated_at: 'Updated At',
		create_trigger: 'Create trigger',
		execute_after: 'Execute after',
		conditions: 'Conditions',
	},
	chat_name: "Chat's name",
	script_name: "Script's name",
	remove: 'Remove',
	edit: 'Edit',
	add: 'Add',
	save: 'Save',
	create: 'Create',
	selected: 'selected',
	cancel: 'Cancel',
	removeTitle: 'Are you sure want to delete <strong>{{name}}</strong>?',
	removesTitle: 'Are you sure want to delete <strong>{{count}}</strong> rows?',
	message_send: 'Send',
	lang: 'Language',
	auth: 'Authorization',
	loginOrEmail: 'Login or email',
	password: 'Password',
	not_found: 'Page not found',
	not_found_title: 'Sorry, page not found!',
	not_found_message:
		'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
	not_found_button: 'Go To Home',
	no_data: 'No data',
	create_chat_in_crm: 'Create chat in CRM',
	types: {
		new_tag: "New tag",
		move_lead: "Move lead",
		create_task: "Create task",
		respond_user_message: "Reply sent to user",
		send_message_to_user: "Send a message to the user",
		user_didnt_respond_to_message: "The user did not respond to the message",
	}
}

export default en

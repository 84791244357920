// @mui
import { AppBar, Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

type Props = {
	isOffset: boolean
}

export default function Header({ isOffset }: Props) {
	return (
		<AppBar color='transparent' sx={{ boxShadow: 0 }}>
			{isOffset && <Shadow />}
		</AppBar>
	)
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
	return (
		<Box
			sx={{
				left: 0,
				right: 0,
				bottom: 0,
				height: 24,
				zIndex: -1,
				m: 'auto',
				borderRadius: '50%',
				position: 'absolute',
				width: `calc(100% - 48px)`,
				boxShadow: theme => theme.customShadows.z8,
				...sx,
			}}
			{...other}
		/>
	)
}
